import axios from "axios";

/*
In order to switch between development and production comment the baseURL and allow all origins on Flask backend
*/
let DEBUG = false;
const baseURL = DEBUG ? "http://localhost:5000/api" : "https://www.altivoli.it/api";

const instance = axios.create({
    baseURL: baseURL,
    timeout: 1000 * 10,
    headers: {
      'Content-Type': 'application/json', // Ensure content type is set for POST/PUT requests
    }
    //withCredentials: true,  // Send cookies when cross-domain requests
});

instance.interceptors.request.use(
  config => {
    // Get the token from localStorage or another secure place
    //config.headers['Content-Type'] = 'application/json';
    const token = localStorage.getItem('token');
    
    if (token) {
      // Attach the token to the Authorization header
      config.headers['Authorization'] = `Bearer ${token}`;
      
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;

