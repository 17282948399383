<template>
  <div class="container-fluid mt-5 pt-5">
    <div class="row m-0 p-0 d-flex justify-content-center" v-if="admin">
      <hr>
      <div class="col-12 text-center">
        <h2 id="adminPanel">Pannello di Controllo</h2>
      </div>

      <div class="col-12 text-center">
        Questo pannello è visibile solo agli amministratori.<br><b><u>ATTENZIONE</u> eliminare un evento è
          irreversibile</b>.
      </div>


      <button class="col-12 col-lg-4 btn mt-3 mb-2 justify-content-center d-flex"
        :style="{ backgroundColor: (create_post || modify_post) ? 'red' : 'green', color: 'white' }"
        @click="createPost">
        {{ (this.create_post || this.modify_post) ? "Annulla" : "Crea nuovo evento" }}
      </button>
      <hr>

    </div>

    <div class="row m-0 p-0">
      <div class="col-12 ">
        <!-- Create Post Form -->
        <div class="container-fluid m-0 p-0" v-if="create_post || modify_post">
          <div class="row">
            <form @submit.prevent="submitPost">
              <div class="mb-3 col-12 col-lg-7">
                <div class="input-group">
                  <span class="input-group-text info">Titolo</span>
                  <input type="text" class="form-control" v-model="new_post_data.title" required>
                </div>
              </div>

              <div class="mb-3 col-12 col-lg-7">

                <div class="input-group">
                  <span class="input-group-text info" id="name">Descrizione</span>
                  <textarea class="form-control" v-model="new_post_data.description" required></textarea>
                </div>

                <p class="mt-3"><b>Preview della descrizione:</b></p>
                <div v-html="new_post_data.description"
                  style="border: 2px solid #007bff; padding: 10px; border-radius: 5px; background-color: #f8f9fa; color: black; max-height: 200px; overflow-y: auto;">
                </div>

                <div class="mt-4" style="background-color: rgba(12, 20, 200, 0.3); margin: 2px; ">
                  <h5><strong>Guida rapida per scrivere articoli in HTML:</strong></h5>
                  <ul>
                    <li>
                      <strong>Grassetto:</strong> Usa <code>&lt;b&gt;testo&lt;/b&gt;</code> per il testo in grassetto.
                      <br><i>Esempio:</i> <code>&lt;b&gt;Questo è grassetto&lt;/b&gt;</code> diventa <b>Questo è
                        grassetto</b>.
                      <br><br>
                    </li>
                    <li>
                      <strong>Corsivo:</strong> Usa <code>&lt;i&gt;testo&lt;/i&gt;</code> per il testo in corsivo.
                      <br><i>Esempio:</i> <code>&lt;i&gt;Questo è corsivo&lt;/i&gt;</code> diventa <i>Questo è
                        corsivo</i>.
                      <br><br>
                    </li>
                    <li>
                      <strong>Line Break:</strong> Usa <code>&lt;br&gt;</code> per andare a capo.
                      <br><i>Esempio:</i> <code>Linea 1&lt;br&gt;Linea 2</code> diventa:<br>Linea 1<br>Linea 2.
                      <br><br>
                    </li>
                    <li>
                      <strong>Sottolineato:</strong> Usa <code>&lt;u&gt;testo&lt;/u&gt;</code> per sottolineare il
                      testo.
                      <br><i>Esempio:</i> <code>&lt;u&gt;Questo è sottolineato&lt;/u&gt;</code> diventa <u>Questo è
                        sottolineato</u>.
                      <br><br>
                    </li>
                    <li>
                      <strong>Liste:</strong>
                      <ul>
                        <li>
                          <strong>Per liste non ordinate:</strong>
                          <code>&lt;ul&gt;&lt;li&gt;Elemento 1&lt;/li&gt;&lt;li&gt;Elemento 2&lt;/li&gt;&lt;/ul&gt;</code>
                          <br><i>Esempio:</i>
                          <code>&lt;ul&gt;&lt;li&gt;Mela&lt;/li&gt;&lt;li&gt;Banana&lt;/li&gt;&lt;/ul&gt;</code>
                          diventa:
                          <ul>
                            <li>Mela</li>
                            <li>Banana</li>
                          </ul>
                          <br>
                        </li>
                        <li>
                          <strong>Per liste ordinate:</strong>
                          <code>&lt;ol&gt;&lt;li&gt;Primo&lt;/li&gt;&lt;li&gt;Secondo&lt;/li&gt;&lt;/ol&gt;</code>
                          <br><i>Esempio:</i>
                          <code>&lt;ol&gt;&lt;li&gt;Primo&lt;/li&gt;&lt;li&gt;Secondo&lt;/li&gt;&lt;/ol&gt;</code>
                          diventa:
                          <ol>
                            <li>Primo</li>
                            <li>Secondo</li>
                          </ol>
                          <br>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p class="text-muted">Puoi copiare e incollare questi tag per formattare il tuo testo.</p>
                </div>
                <hr>

              </div>

              <div class="form-check form-switch mb-3">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitch"
                  v-model="new_post_data.onlymembers">
                <label class="form-check-label" for="flexSwitch">Evento riservato <b class="info">solo ai Soci?</b>
                  Opzione selezionata: <b>{{ this.new_post_data.onlymembers ? 'Si' : 'No' }}</b> </label>
              </div>

              <div class="mb-3 col-12 col-lg-7">
                <div class="form-group">
                  <span class="input-group-text info">Data dell'evento</span>
                  <input type="date" class="form-control" v-model="new_post_data.event_date">
                </div>
              </div>

              <div class="mb-3 col-12 col-lg-7">
                <div class="input-group">
                  <span class="input-group-text info">Copertina dell'evento</span>
                  <input type="file" class="form-control" id="fileUploader" @change="uploadFile">
                </div>
              </div>

              <button id="createButton" class="btn col-6 col-lg-4 m-3" type="submit"
                :style="{ backgroundColor: (create_post) ? 'green' : '#ffc720', color: 'white' }">
                {{ this.modify_post ? "Modifica" : "Crea" }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row p-2 m-0 justify-content-center" v-if="posts.length > 0 && !isLargeScreen">
      <div class="col-12 col-lg-6" v-for="post in posts" :key="post.id">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="card mb-3">
                <h1 class="card-title mt-2 mb-3 text-center">{{ post.title }}</h1>
                <img :src="`https://localhost:5000/posts/${post.id}/image`" class="card-img-top" alt="Cover Image">
                <div class="card-body">
                  <p><b>Descrizione</b></p>
                  <span v-html="post.description" class="card-text mb-2"></span>
                  <p v-if="post.onlymembers" class="text-danger mb-2"><strong>Evento riservato solo ai soci</strong></p>
                  <p><b>Evento pianificato per il</b>: {{ post.event_date }}</p>
                  <div v-if="admin">
                    <button class="btn btn-warning me-2" @click="editPost(post)">Modifica</button>
                    <button class="btn btn-danger" @click="deletePost(post.id)">Elimina</button>
                  </div>
                </div>
                <div class="card-footer">
                  <p class="card-text">
                    <small class="text-body-secondary">Pubblicato il {{ post.timestamp }}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row p-2 m-0 justify-content-center" v-if="posts.length > 0 && isLargeScreen">
      <div class="col-12 col-lg-8" v-for="post in posts" :key="post.id">
        <div class="container-fluid desk border rounded">
          <div class="row align-items-start mb-4">
            <!-- Left Column for the Image -->
            <div class="col-md-5 mt-2">
              <img :src="`https://localhost:5000/posts/${post.id}/image`" class="img-fluid" alt="Cover Image">
            </div>
            <!-- Right Column for the Title, Description, and Other Info -->
            <div class="col-md-7 d-flex flex-column">
              <!-- Title at the Top -->
              <h1 class="card-title mb-3 mt-3 text-center align-self-center">{{ post.title }}</h1>

              <!-- Centered Description -->
              <div class="card-body mt-5 text-start flex-grow-1 align-items-bottom ">
                <h5><b>Descrizione</b></h5>
                <span v-html="post.description" class="card-text mb-2"></span>
                <p v-if="post.onlymembers" class="text-danger mb-2"><strong>Evento riservato solo ai soci</strong></p>
              </div>

              <!-- Event Date and Buttons at the Bottom -->
              <div class="d-flex justify-content-between mt-5 p-2 align-items-center">
                <p><b>Evento pianificato per il</b>: {{ post.event_date }}</p>
                <div v-if="admin" class="d-flex">
                  <button class="btn btn-warning me-2" @click="editPost(post)">Modifica</button>
                  <button class="btn btn-danger" @click="deletePost(post.id)">Elimina</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Footer for additional info (e.g., timestamp) -->
          <div class="card-footer text-center">
            <p class="card-text">
              <small class="text-body-secondary">Pubblicato il {{ post.timestamp }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>



    <div class="row justify-content-center text-center align-items-center" style="height: 600px;"
      v-if="posts.length === 0">
      <h2>Sembra che non ci siano ancora eventi disponibili!</h2>
    </div>
  </div>
</template>

<style scoped>
.info {
  color: red;
  font-weight: bold;
}

.desk {
  border: 2px solid black;
  border-radius: 10px;
}

.desk .card-body {
  text-align: left; /* Ensure text alignment */
  margin-top: 20px; /* Optional spacing */
}
</style>

<script>
export default {
  data() {

    return {
      create_post: false,
      modify_post: false,
      editing_post_id: null,
      new_post_data: {
        title: '',
        description: '',
        onlymembers: false,
        event_date: '',
      },
      image: null,
      posts: [],
      windowWidth: window.innerWidth
    }
  },
  props: {
    admin: Boolean,
  },

  computed: {
    isLargeScreen() {
      return this.windowWidth > 1100;
    }
  },


  async mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
    try {
      let req = await this.$axios.get("/posts");
      if (req.status === 200) this.posts = req.data.data;
    } catch (error) {
      alert("Errore durante il caricamento degli eventi");
    }

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },


  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },


    async uploadFile() {
      let fileInput = document.getElementById('fileUploader');
      const file = fileInput.files[0];

      if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
        fileInput.value = '';
        return alert("Il file deve essere un'immagine in formato JPEG, JPG o PNG");
      }

      try {
        const arrayBuffer = await this.readFileAsArrayBuffer(file);
        this.image = {
          content: arrayBuffer,
          type: file.type,
          name: file.name
        };

      } catch (error) { alert("Errore durante il caricamento del file"); }
    },

    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => { resolve(reader.result); };
        reader.onerror = (error) => { reject(error); };
        reader.readAsArrayBuffer(file);
      });
    },

    createPost() {
      if (this.modify_post) {
        this.modify_post = false;
        this.editing_post_id = null;
        this.new_post_data = {};
        return
      }
      this.create_post = !this.create_post;
    },


    editPost(post) {
      document.getElementById('adminPanel').scrollIntoView();
      this.create_post = false;
      this.modify_post = true;
      this.editing_post_id = post.id; // Imposta l'ID dell'evento che stiamo modificando

      this.new_post_data = {
        title: post.title,
        description: post.description,
        onlymembers: post.onlymembers,
        event_date: post.event_date,
      };
    },


    async deletePost(post_id) {
      try {
        let delete_response = await this.$axios.delete(`/posts/${post_id}`);

        if (delete_response.status === 200) {
          this.posts = this.posts.filter(post => post.id !== post_id);
        }

        this.create_post = false;
        this.modify_post = false;
      } catch (error) {
        alert("Errore durante l'eliminazione dell'evento");
        console.error(error);
      }
    },

    async submitPost() {
      try {
        if (this.image === null && this.create_post === true) return alert("Devi caricare un'immagine di copertina per l'evento");
        const formData = new FormData();

        for (let key in this.new_post_data) formData.append(key, this.new_post_data[key]);

        if (this.image && this.image.content) {
          formData.append('file', new Blob([this.image.content], { type: this.image.type }), this.image.name);
        }

        let post_response;
        if (this.modify_post) {
          post_response = await this.$axios.put(`/posts/${this.editing_post_id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        } else {
          post_response = await this.$axios.post("/posts", formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        }

        if (post_response.status === 201 || post_response.status === 200) {
          if (this.modify_post) {
            const index = this.posts.findIndex(post => post.id === this.editing_post_id);
            this.posts.splice(index, 1, { ...this.new_post_data, 'timestamp': new Date().toLocaleString() }); // Aggiorna il post nella lista
          } else {
            this.posts.push({ ...this.new_post_data, id: post_response.data.id });
          }
        }

        //console.log("RISPOSTA BACKEND HTTP:", post_response)

        // Reset dati dopo invio
        this.image = null;
        this.new_post_data = {};
        this.modify_post = false;
        this.create_post = false;
        this.editing_post_id = null;

      } catch (error) {
        alert("Errore durante la creazione/modifica dell'evento");
        console.error(error);
      }
    }
  }
}
</script>
